<template>
	<page-content>
		<page-title heading="ตั้งค่าจำนวนโควต้าแผนการสอน">
			<template v-slot:right>
        <b-button variant="danger" @click="onCreate"
          >เพิ่มโควต้าสถานี</b-button
        >
      </template>
		</page-title>

    <filter-master
      v-model="filterMaster"
      :m-headquarter-id="
        !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
      "
      :m-division-id="
        !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
      "
      :m-station-id="
        !authUser.canAccessAllStations ? authUser.mStationId : null
      "
      :disabledInput="{
        headQuarter:
          !authUser.canAccessAllHeadQuarters &&
          authUser.mHeadquarterId !== null,
        division:
          !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
        station: !authUser.canAccessAllStations && authUser.mStationId !== null,
      }"
      col-headquarter="4"
      col-division="4"
      col-station="4"
      :hidden-input="{ school: true, schoolClass: true, room: true }"
    >
    </filter-master>

		<filter-fiscal-year-term
			v-model="formFiscalYearTerm"
			:hiddenInput="{ fiscalYearTo: true, termTo: true }"
			labelFiscalYearFrom="ปีการศึกษา"
		/>

    <b-row class="row-content">
      <b-col cols="12">
        <b-row align-v="center" align-h="between">
          <b-col cols="8">
            <h5 class="mb-0">เปิด/ปิด การแสดงผลโควต้าแผนการสอนทั้งหมด</h5>
          </b-col>
          <b-col cols="4" class="text-right">
            <toggle-button
              color="#3d6b99"
              :value="dataIsOpen"
              @change="toggleVisible($event.value)"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

		<config-quota-table
			ref="table"
      class="mt-4"
      :fields="fields"
      :params="params"
		></config-quota-table>

    <create-config-quota
      :edit-data.sync="editData"
      v-model="showModalCreate"
      @create:success="onCreateSuccess"
    ></create-config-quota>
	</page-content>
</template>
  
<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from '../../components/layout/PageTitle';
import FilterMaster from "../../components/form/FilterMaster";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
import { Auth, ConfigQuota } from "../../models";
import ConfigQuotaTable from "../../components/table/ConfigQuota";
import CreateConfigQuota from '../../components/modal/CreateConfigQuota';
import { ToggleButton } from "vue-js-toggle-button";

const defaultValue = "ทั้งหมด";

export default {
	components: {
		PageContent,
		PageTitle,
    FilterMaster,
    FilterFiscalYearTerm,
		ConfigQuotaTable,
    CreateConfigQuota,
    ToggleButton,
	},

	data() {
		return {
      defaultValue,
      filterMaster: {},
      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
      },
			showModalCreate: false,
      editData: {},
      dataIsOpen: false,
		}
	},

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      let fields = [{ key: "station", label: "สถานี", sortable: true }];

      fields = [
        ...fields,
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "balance", label: "โควต้าคงเหลือ (ห้อง)", sortable: true },
        { key: "quota", label: "โควต้าทั้งหมด (ห้อง)", sortable: true },
        {
          key: "created_at",
          label: "วันที่สร้าง",
          sortable: true,
        },
				{ key: 'actions', label: 'จัดการ' },
      ];

      return fields;
    },

    params() {
      const {
        filterMaster: params
      } = this;

      let additionalParams = {};

      return {
        mDivisionId: params.mDivisionId,
        mHeadquarterId: params.mHeadquarterId,
        mStationId: params.mStationId,
        ...this.getFilterTermYear(),
        ...additionalParams,
      };
    },
  },

	methods: {
		getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscal_year",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "term", this.formFiscalYearTerm.termFrom);
      }

      return params;
    },

    onCreate() {
      this.showModalCreate = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    objectToQueryString(obj) {
      const keys = Object.keys(obj);
      const keyValuePairs = keys.map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key] !== null ? obj[key] : '');
      });
      return keyValuePairs.join('&');
    },

    async toggleVisible(value) {
      // console.log('----item---', value, '=========', this.params)
      let params = this.objectToQueryString(this.params)

      let promise;

      try {
        promise = await ConfigQuota.api().updateAll(params, {
          is_open: value,
        });
        const { data } = promise.response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(`อัพเดตการแสดงผลโควต้าแผนการสอนทั้งหมดเรียบร้อยแล้ว`);
        } else {
          this.$toast.error(
            `อัพเดตการแสดงผลโควต้าแผนการสอนทั้งหมดไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถตั้งค่าโควต้าแผนการสอนทั้งหมดได้ กรุณาลองใหม่อีกครั้ง");
      }

      this.$refs.table.refresh();
    },
	},
}
</script>
  
<style lang="scss" scoped>
.row-content {
  margin: 16px 0;
  padding: 12px 0;
  border-radius: 16px;
	border: 0.2rem solid #3d6b99;

  h5 {
    font-size: 1.125rem;
  }

  /* --angle: 0deg;
  width: 100%;
	height: 100%;
	border: 0.5rem solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	
	animation: 3s rotate linear infinite; */
}

@keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
</style>
