var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card__flex card card-box mb-3"},[_c('div',{staticClass:"card-header pr-2"},[_c('div',{staticClass:"card-header--title"},[_c('small',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"card-header--actions"},[_vm._t("header-actions")],2)]),_c('div',{staticClass:"card-body p-0"},[_c('base-table',{ref:"table",attrs:{"provider":_vm.fetch,"fields":_vm.fields,"is-busy":_vm.isFetching,"current-page":_vm.currentPage,"number-of-pages":_vm.numberOfPages,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"per-page":10},on:{"update:isBusy":function($event){_vm.isFetching=$event},"update:is-busy":function($event){_vm.isFetching=$event},"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"head(actions)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(row.label))])])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"text-center"},[_c('toggle-button',{key:("toggle-" + (item.id) + "-" + index),attrs:{"color":"#3d6b99","value":item.is_open,"sync":true},on:{"change":function($event){return _vm.toggleVisible(item, $event.value)}}})],1)]}},{key:"cell(balance)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.balance))])])]}},{key:"cell(quota)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.quota))])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }