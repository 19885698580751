var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-content',[_c('page-title',{attrs:{"heading":"ตั้งค่าจำนวนโควต้าแผนการสอน"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.onCreate}},[_vm._v("เพิ่มโควต้าสถานี")])]},proxy:true}])}),_c('filter-master',{attrs:{"m-headquarter-id":!_vm.authUser.canAccessAllHeadQuarters ? _vm.authUser.mHeadquarterId : null,"m-division-id":!_vm.authUser.canAccessAllDivisions ? _vm.authUser.mDivisionId : null,"m-station-id":!_vm.authUser.canAccessAllStations ? _vm.authUser.mStationId : null,"disabledInput":{
        headQuarter:
          !_vm.authUser.canAccessAllHeadQuarters &&
          _vm.authUser.mHeadquarterId !== null,
        division:
          !_vm.authUser.canAccessAllDivisions && _vm.authUser.mDivisionId !== null,
        station: !_vm.authUser.canAccessAllStations && _vm.authUser.mStationId !== null,
      },"col-headquarter":"4","col-division":"4","col-station":"4","hidden-input":{ school: true, schoolClass: true, room: true }},model:{value:(_vm.filterMaster),callback:function ($$v) {_vm.filterMaster=$$v},expression:"filterMaster"}}),_c('filter-fiscal-year-term',{attrs:{"hiddenInput":{ fiscalYearTo: true, termTo: true },"labelFiscalYearFrom":"ปีการศึกษา"},model:{value:(_vm.formFiscalYearTerm),callback:function ($$v) {_vm.formFiscalYearTerm=$$v},expression:"formFiscalYearTerm"}}),_c('b-row',{staticClass:"row-content"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{attrs:{"align-v":"center","align-h":"between"}},[_c('b-col',{attrs:{"cols":"8"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("เปิด/ปิด การแสดงผลโควต้าแผนการสอนทั้งหมด")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('toggle-button',{attrs:{"color":"#3d6b99","value":_vm.dataIsOpen},on:{"change":function($event){return _vm.toggleVisible($event.value)}}})],1)],1)],1)],1),_c('config-quota-table',{ref:"table",staticClass:"mt-4",attrs:{"fields":_vm.fields,"params":_vm.params}}),_c('create-config-quota',{attrs:{"edit-data":_vm.editData},on:{"update:editData":function($event){_vm.editData=$event},"update:edit-data":function($event){_vm.editData=$event},"create:success":_vm.onCreateSuccess},model:{value:(_vm.showModalCreate),callback:function ($$v) {_vm.showModalCreate=$$v},expression:"showModalCreate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }