<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-0">
        <filter-master
          v-model="filterMaster"
          :m-headquarter-id="
            editData && editData.mHeadquarterId
              ? editData.mHeadquarterId
              : null
          "
          :m-division-id="
            editData && editData.mDivisionId
              ? editData.mDivisionId
              : null
          "
          :m-station-id="
            editData && editData.mStationId
              ? editData.mStationId
              : null
          "
          :disabled-input="disabledInput"
          col-headquarter="6"
          col-division="6"
          col-station="12"
          default-label="-"
          :hidden-input="{
            school: true,
            schoolClass: true,
            room: true,
          }"
          :invalid="invalid"
          :validation="validation"
        >
        </filter-master>
      </div>

      <div class="col-6 px-2 mb-2">
        <label class="font-weight-bold" for="fiscalYear">ปีการศึกษา</label>

        <v-select
          id="fiscalYear"
          v-model="fiscal_year"
          :options="fiscalYears"
          :loading="isFetchingSemester"
        ></v-select>
      </div>

      <div class="col-6 px-2 mb-2">
        <label class="font-weight-bold" for="term">เทอม</label>

        <v-select
          id="term"
          v-model="term"
          :options="terms"
          :loading="isFetchingSemester"
        ></v-select>
      </div>

      <div class="col-12 px-2 mb-2">
        <label class="font-weight-bold" for="quotaNumber">จำนวนโควต้า</label>

        <b-form-input
          id="quotaNumber"
          :state="invalid ? validation.form.quota.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.quota"
        ></b-form-input>

        <b-form-invalid-feedback id="quotaNumber-feedback">
          กรุณากรอกจำนวนโควต้า
        </b-form-invalid-feedback>
      </div>
    </div>
  </b-form>
</template>

<script>
import vSelect from "vue-select";
import { MSemester } from "../../models";
import FilterMaster from "./FilterMaster";

export default {
  props: {
    formData: Object,
    editData: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
    defaultId: {
      type: [Number, String],
      default: null,
    },
    defaultLabel: {
      type: String,
      default: "ทั้งหมด",
    },
    labelStation: {
      type: String,
      default: "สถานี",
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    FilterMaster,
    "v-select": vSelect,
  },

  data() {
    return {
      form: {
        quota: this.editData.quota || null,
        mSemesterId: this.editData.mSemesterId || null,
      },
      filterMaster: {
        mHeadquarterId: this.editData.mHeadquarterId || null,
        mDivisionId: this.editData.mDivisionId || null,
        mStationId: this.editData.mStationId || null,
      },
      isFetchingSemester: false,
      fiscal_year: "",
      term: "",
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
    filterMaster: {
      deep: true,
      immediate: true,
      handler: "syncFilterMaster",
    },
    fiscal_year: {
      handler: "onFiscalYearChanged",
    },
    term: {
      handler: "onTermChanged",
    },
  },

  computed: {
    // activeSemesters() {
    //   return MSemester.query()
    //     .where('isActive', true)
    //     .orderBy('fiscal_year', 'desc')
    //     .orderBy('term', 'desc')
    //     .get();
    // },
    allSemesters() {
      let query = MSemester.query()
        .orderBy('fiscal_year', 'desc')
        .orderBy('term', 'desc')
        .get();
      
      return query.filter(({ fiscal_year }) => fiscal_year !== null);
    },

    defaultSemester() {
      return this.allSemesters.length
        ? this.allSemesters[0]
        : null;
    },

    fiscalYears() {
      const { allSemesters = [] } = this;

      const fiscalYears = allSemesters.map(({ fiscal_year }) => fiscal_year);

      return [...new Set(fiscalYears)];
    },

    terms() {
      const { fiscal_year: selected_year, allSemesters = [] } = this;
      
      let terms = [];

      if (selected_year) {
        const filteredActiveSemesters = allSemesters.filter(({ fiscal_year }) => selected_year == fiscal_year);

        if (filteredActiveSemesters.length) {
          const filteredTerms = filteredActiveSemesters.map(({ term }) => term);

          terms = [...new Set(filteredTerms)];
        }
      }
      return terms;
    },
  },

  methods: {
    syncData(v) {
      this.$emit("change", v);
    },

    syncFilterMaster(v) {
      this.$emit("update:filterMaster", v);
    },

    async onFiscalYearChanged(selected_year = null) {
      let id = null;

      const { term: selected_term, allSemesters = [] } = this;

      if (selected_term && selected_year) {
        const findSemesters = allSemesters.find(({ fiscal_year, term }) => 
          selected_term == term &&
          selected_year == fiscal_year
        );

        id = findSemesters.id
      }

      this.$set(this.form, "mSemesterId", id);
    },

    async onTermChanged(selected_term = null) {
      let id = null;

      const { fiscal_year: selected_year, allSemesters = [] } = this;

      if (selected_year && selected_term) {
        const findSemesters = allSemesters.find(({ fiscal_year, term }) => 
          selected_year == fiscal_year &&
          selected_term == term
        );

        id = findSemesters.id
      }

      this.$set(this.form, "mSemesterId", id);
    },

    async fetchSemesters() {
      this.isFetchingSemester = true;

      try {
        await MSemester.api().findAll();
      } catch (error) {
        this.$toast.error("ไม่สามารถดึงข้อมูลปีการศึกษาได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingSemester = false;
      }

      if (!(this.editData && this.editData.id) && this.defaultSemester) {
        const { fiscal_year, term } = this.defaultSemester;

        this.$set(this, "fiscal_year", fiscal_year);
        this.$set(this, "term", term);
      }
    },
  },

  mounted() {
    this.fetchSemesters();
  },
};
</script>
