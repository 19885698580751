<template>
  <page-content>
    <page-title heading="ตั้งค่าชั้นเรียน"></page-title>

    <config-m-class-table
      ref="configTable"
      class="my-4"
      :fields="configFields"
      :params="configTableParams"
    />
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from '../../components/layout/PageTitle.vue';
import ConfigMClassTable from "../../components/table/ConfigMClass.vue";

export default {
  components: {
    PageContent,
    PageTitle,
    ConfigMClassTable,
  },

  data() {
    return {
      configTableParams: {},
      configFields: [
        { key: "m_class_name", label: "ชั้นเรียน" },
        { key: 'actions', label: 'จัดการ' },
        { key: "refresh", label: "", thStyle: "width: 25px;" },
      ],
    }
  },
}
</script>
