<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-2 mb-2">
        <b-form-textarea
          id="guidelineTitle"
          :state="invalid ? validation.form.guideline_withdraw_title.required : null"
          type="text"
          placeholder="เพิ่มประกาศ"
          rows="2"
          max-rows="4"
          :disabled="disabled"
          v-model="form.guideline_withdraw_title"
        ></b-form-textarea>

        <b-form-invalid-feedback id="announcementTitle-feedback">
          กรุณากรอกข้อมูลคู่มือ
        </b-form-invalid-feedback>
      </div>
    </div>

    <div class="d-flex flex-row my-3">
      <div class="col-12 px-2">
        <label class="font-weight-bold ft-s-14" for="bannerImg"
          >อัพโหลดคู่มือ</label
        >

        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          :disabled="disabled"
          @click="toggleAttachFile"
        >
          เลือกไฟล์
        </b-button>

        <small class="text-black-50 ml-2 font-weight-bold"
          >*PDF ขนาดไม่เกิน 10MB</small
        >

        <input
          v-show="false"
          type="file"
          :id="`announcement-image-file`"
          name="addGuidelinePdf"
          accept="application/pdf"
          ref="addGuidelinePdf"
          @change="previewPdf($event)"
        />
      </div>
    </div>
    
    <div class="download-link" v-if="form.file || form.key_img !== null">
      <a :href="form.file ? `` : form.pdf_url" download>
        {{ form.file ? form.file.name : form.key_img.split("/").pop() }}
      </a>
    </div>
  </b-form>
</template>

<script>
// import Compressor from "compressorjs";
import { Auth } from "../../models";

export default {
  props: {
    formData: Object,

    editData: {
      type: Object,
      default: () => {},
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  data() {
    return {
      isProcessing: false,
      form: {
        id: this.editData.id || null,
        guideline_withdraw_title: this.editData.guideline_withdraw_title || "",
        key_img: this.editData.key_img || null,
        pdf_url: this.editData.pdf_url || "",
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    syncData(v) {
      this.$emit("change", v);
    },

    toggleAttachFile() {
      this.$nextTick(() => {
        const { addGuidelinePdf } = this.$refs;
        addGuidelinePdf.click();
      });
    },

    async previewPdf(event) {
      const self = this;

      var input = event.target;

      const maxAllowedSize = 20 * 1024 * 1024;

      Array.from(input.files).forEach((file) => {
        if (file.size <= maxAllowedSize) {
          this.isProcessing = true;
          
          self.$set(self.form, "file", file);

          // new Compressor(file, {
          //   quality: 0.8,

          //   // maxWidth: width,

          //   // maxHeight: height,

          //   resize: "cover",

          //   // The compression process is asynchronous,
          //   // which means you have to access the `result` in the `success` hook function.
          //   success(result) {
          //     var reader = new FileReader();

          //     reader.onload = async (e) => {
          //       self.$set(self.form, "file", result);
          //       self.$set(self.form, "raw", e.target.result);

          //       self.isProcessing = false;
          //     };

          //     reader.readAsDataURL(result);
          //   },
          //   error(err) {
          //     console.log(err.message);
          //     this.$toast.error("ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
          //   },
          // });
        } else {
          this.$toast.error(
            "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 10 MB ได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      });

      input.value = "";
    },
    
    deleteImage() {
      this.form.pdf_url = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  background-color: #ffffff;
  border: 1px solid #3d6b99;
  border-radius: 50%;
  padding: 3px;

  &:focus {
    outline: none;
  }
}
.wrap-image {
  position: relative;
  width: 150px;
  height: 150px;

  > img {
    object-fit: cover;
  }
}
.btn-select-file {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:focus {
    outline: 1px dashed #d9d9d9;
  }
}
.download-link {
  width: max-content;
  background-color: #f4f5fd;
  padding: 8px 16px;
  border-radius: 0.65rem;
}
</style>
