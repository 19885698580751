<template>
  <b-modal
    id="create-or-update-video-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไขข้อมูลวีดีโอ</h3>
      <span v-if="updatedAt">อัพเดตล่าสุด: {{ updatedAt }}</span>
    </template>

    <div class="col-12 px-2 mb-3">
      <label class="font-weight-bold mb-2" for="stationName">ชื่อวีดีโอ</label>
      <b-form-input
        id="stationName"
        type="text"
        v-model="videoName"
      ></b-form-input>
    </div>

    <b-embed v-if="videoSrc" type="video" controls class="mt-3 p-2">
      <source :src="videoSrc" type="video/mp4" />
    </b-embed>

    <b-embed v-if="googleDriveSrc" type="video" controls class="mt-3 p-2">
      <source :src="googleDriveSrc" type="video/mp4" />
    </b-embed>

    <b-embed
      v-if="youtubeSrc"
      :src="youtubeSrc"
      class="mt-3 p-2"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </b-embed>

    <div
      v-if="videoSrc || youtubeSrc || googleDriveSrc"
      class="col-12 mb-5"
      style="text-align: center"
    >
      <b-button
        variant="danger"
        size="sm"
        class="mt-3"
        @click="clearSrc"
        :disabled="!videoSrc && !youtubeSrc && !googleDriveSrc"
        >ลบเพื่ออัพโหลดใหม่</b-button
      >
    </div>

    <div class="col-12 px-2 mt-4">
      <label class="font-weight-bold">อัพโหลดวีดีโอจากยูทูป</label>
      <small class="text-black-50 ml-2 font-weight-bold"
        >ตัวอย่าง: https://www.youtube.com/watch?v=****</small
      >
    </div>

    <div class="d-flex flex-row flex-wrap px-2 my-4 justify-content-between">
      <b-form-input
        id="stationName"
        type="text"
        class="col-7 col-md-10"
        :disabled="videoSrc !== '' || googleDriveSrc !== ''"
        v-model="youTubeUrl"
        placeholder="วางลิงก์ YouTube ที่นี่"
      ></b-form-input>

      <span class="col-5 col-md-2">
        <b-button
          size="sm"
          variant="primary"
          :disabled="videoSrc !== '' || googleDriveSrc !== ''"
          @click="validateYouTubeUrl"
        >
          ตรวจสอบลิงก์
        </b-button>
      </span>
    </div>

    <div class="separator">หรือ</div>

    <div class="col-12 px-2 mt-4">
      <label class="font-weight-bold">อัพโหลดวีดีโอจาก Google Drive</label>
      <small class="text-black-50 ml-2 font-weight-bold"
        >ตัวอย่างลิ้งค์: https://drive.google.com/file/d/****/view?usp=sharing
        (ไฟล์ขนาดไม่ควรเกิน 100 MB)</small
      >
    </div>

    <div class="d-flex flex-row flex-wrap px-2 my-4 justify-content-between">
      <b-form-input
        id="stationName"
        type="text"
        class="col-7 col-md-10"
        :disabled="videoSrc !== '' || youtubeSrc !== ''"
        v-model="googleDriveUrl"
        placeholder="วางลิงก์ Google Drive ที่นี่"
      ></b-form-input>

      <span class="col-5 col-md-2">
        <b-button
          size="sm"
          variant="primary"
          :disabled="videoSrc !== '' || youtubeSrc !== ''"
          @click="validateGoogleDriveUrl"
        >
          ตรวจสอบลิงก์
        </b-button>
      </span>
    </div>

    <div class="separator">หรือ</div>

    <div class="d-flex flex-row my-4">
      <div class="col-10 px-2">
        <label class="font-weight-bold" for="bannerImg"
          >อัพโหลดวีดีโอจากเครื่อง</label
        >

        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          :disabled="youtubeSrc !== '' || googleDriveSrc !== ''"
          @click="onClickUploadVideo"
        >
          เลือกไฟล์
        </b-button>

        <input
          v-show="false"
          type="file"
          accept="video/*"
          @change="handleFileUpload($event)"
          ref="upload__video"
        />

        <!-- <small class="text-black-50 ml-2 font-weight-bold"
          >*JPG, PNG ขนาดไม่เกิน 10MB (ขนาดแนะนำ {{ width }} x
          {{ height }} px)</small
        > -->
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        @click.prevent="save"
        :disabled="!videoSrc && !youtubeSrc && !googleDriveSrc"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import { Auth, Video } from "../../models";
import axios from "axios";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {},

  props: {
    show: Boolean,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  watch: {
    editData: {
      immediate: true,
      handler: "cloneEditData",
    },
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      form: {},
      youTubeUrl: "",
      googleDriveUrl: "",
      youtubeSrc: "",
      googleDriveSrc: "",
      videoName: "",
      videoSrc: "",
      updatedAt: "",
      disabled: false,
    };
  },

  // validations: {
  //   form: {
  //     banner_title: { required },
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    cloneEditData(v) {
      if (!v) return;

      if (this.validateYouTubeUrlforEdit(v.keyVdo)) {
        this.youtubeSrc = v.keyVdo;
      } else if (this.validateGoogleDriveUrlforEdit(v.keyVdo)) {
        this.googleDriveSrc = v.keyVdo;
      } else {
        this.videoSrc = v.keyVdo;
      }

      this.updatedAt = v.updatedAt;
      this.videoName = v.video_name;
    },

    onClickUploadVideo() {
      this.$nextTick(() => {
        this.$refs.upload__video.click();
      });
    },

    previewVideo() {
      this.videoSrc = "";
      const self = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.form.file);
      reader.addEventListener("load", function() {
        self.videoSrc = reader.result;
      });
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;
      this.$set(this.form, "file", file);
      this.previewVideo();
      file.value = "";
    },
    async save() {
      // this.$v.$touch();

      // this.invalid = false;

      // if (this.$v.$invalid) {
      //   this.invalid = true;

      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }

      let formData = {
        ...this.form,
      };

      this.isSubmiting = true;

      let promise;

      try {
        if (!this.videoName) return this.$toast.error(`กรุณาใส่ชื่อวีดีโอ`);

        if (this.editData) {
          const { id } = this.editData;

          if (this.youtubeSrc || this.googleDriveSrc) {
            let keyVdo;

            if (this.youtubeSrc) {
              keyVdo = this.youtubeSrc;
            }

            if (this.googleDriveSrc) {
              keyVdo = this.googleDriveSrc;
            }

            promise = await Video.api().update(id, {
              video_name: this.videoName,
              keyVdo,
            });
            const { data } = promise.response;

            if (data && data.response_status == "SUCCESS") {
              this.$toast.success(`อัพโหลดวีดีโอเรียบร้อยแล้ว`);

              this.$bvModal.hide("create-or-update-video-modal");

              this.$emit("create:success");
            } else {
              this.$toast.error(`อัพโหลดวีดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }
          } else {
            // formData = await this.uploadVideo(formData);

            let formDataUpload = new FormData();

            formDataUpload.append("files", formData.file);

            let uploadResponse = await axios.post(
              "https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData",
              formDataUpload
            );
            // console.log(uploadResponse.data.data);

            promise = await Video.api().update(id, {
              video_name: this.videoName,
              keyVdo: uploadResponse.data.data.url,
            });
            const { data } = promise.response;

            if (data && data.response_status == "SUCCESS") {
              this.$toast.success(`อัพโหลดวีดีโอเรียบร้อยแล้ว`);

              this.$bvModal.hide("create-or-update-video-modal");

              this.$emit("create:success");
            } else {
              this.$toast.error(`อัพโหลดวีดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }
          }
        } else {
          if (this.youtubeSrc || this.googleDriveSrc) {
            let keyVdo;

            if (this.youtubeSrc) {
              keyVdo = this.youtubeSrc;
            }

            if (this.googleDriveSrc) {
              keyVdo = this.googleDriveSrc;
            }

            promise = await Video.api().create({
              video_name: this.videoName,
              keyVdo,
            });

            const { data } = promise.response;

            if (data && data.response_status == "SUCCESS") {
              this.$toast.success(`อัพโหลดวีดีโอเรียบร้อยแล้ว`);

              this.$bvModal.hide("create-or-update-video-modal");

              this.$emit("create:success");
            } else {
              this.$toast.error(`อัพโหลดวีดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }
          } else {
            formData = await this.uploadVideo(formData);
            promise = await Video.api().create({
              video_name: this.videoName,
              keyVdo: formData.keyVdo,
            });
            const { data } = promise.response;

            if (data && data.response_status == "SUCCESS") {
              this.$toast.success(`อัพโหลดวีดีโอเรียบร้อยแล้ว`);

              this.$bvModal.hide("create-or-update-video-modal");

              this.$emit("create:success");
            } else {
              this.$toast.error(`อัพโหลดวีดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }
          }
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    validateYouTubeUrlforEdit(src = "") {
      if (src != undefined || src != "") {
        var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (src.match(regExp)) {
          return true;
        } else {
          return false;
        }
      }
    },

    validateGoogleDriveUrlforEdit(src = "") {
      if (src != undefined || src != "") {
        const regExp = /https:\/\/drive\.google\.com\/uc\?export=download&id=(.*?)/g;

        if (src.match(regExp)) {
          return true;
        } else {
          return false;
        }
      }
    },

    validateYouTubeUrl() {
      this.youtubeSrc = "";

      if (this.youTubeUrl != undefined || this.youTubeUrl != "") {
        var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (this.youTubeUrl.match(regExp)) {
          this.youtubeSrc = this.youTubeUrl.replace("watch?v=", "embed/");
        } else {
          this.$toast.error(`ลิงก์ยูทูปไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง`);
        }
      }
    },

    validateGoogleDriveUrl() {
      if (this.googleDriveUrl != undefined || this.googleDriveUrl != "") {
        const regExp = /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?\?usp=sharing/g;

        const matchResult = this.googleDriveUrl.match(regExp);

        if (matchResult) {
          this.googleDriveSrc = matchResult[0].replace(
            regExp,
            "https://drive.google.com/uc?export=download&id=$1"
          );
        } else {
          this.$toast.error(
            `ลิงก์ Google Drive ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง`
          );
        }
      }
    },

    async uploadVideo(form) {
      if (form.file) {
        const presignPromise = await Video.api().getUploadUrl({
          video_name: form.file.name,
          contentType: form.file.type,
        });

        const { url, fullUrl } = presignPromise.response.data;

        if (url) {
          await axios.put(url, form.file);
        }

        this.$set(form, "keyVdo", fullUrl);
        this.$set(form, "video_name", form.file.name);
      }

      return form;
    },

    onHidden() {
      this.clearData();
      this.$emit("onHide");
    },

    clearSrc() {
      this.youTubeUrl = "";
      this.videoSrc = "";
      this.youtubeSrc = "";
      this.googleDriveUrl = "";
      this.googleDriveSrc = "";
    },

    clearData() {
      this.clearSrc();

      this.invalid = false;
      this.form = {};
      this.videoName = "";
      this.updatedAt = "";
      this.disabled = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #d1d2db;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
