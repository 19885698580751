<template>
  <page-content>
    <page-title heading="จัดการแบนเนอร์และข่าวประชาสัมพันธ์"> </page-title>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการแบนเนอร์</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalBanner = true"
              >เพิ่มแบนเนอร์</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <banner-table
      ref="bannerTable"
      class="my-4"
      :fields="bannerFields"
      :params="bannerTableParams"
      @edit="onEditBanner"
      @delete="onDeleteBanner"
    />

    <create-or-update-banner
      :edit-data.sync="bannerEditData"
      v-model="showModalBanner"
      @create:success="onCreateBannerSuccess"
    ></create-or-update-banner>

    <delete-banner
      v-model="showModalDeleteBanner"
      @delete:success="onDeleteBannerSuccess"
      :delete-data.sync="bannerDeleteData"
    ></delete-banner>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการวีดีโอ</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalVideo = true"
              >เพิ่มวีดีโอ</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <video-table
      ref="videoTable"
      class="mt-4"
      :fields="videoFields"
      @edit="onEditVideo"
      @on-hide="onHideVideo"
      @delete="onDeleteVideo"
    />

    <create-or-update-video
      v-model="showModalVideo"
      :edit-data.sync="videoEditData"
      @create:success="onCreateVideoSuccess"
    ></create-or-update-video>

    <delete-video
      v-model="showModalDeleteVideo"
      @delete:success="onDeleteVideoSuccess"
      :delete-data.sync="videoDeleteData"
    ></delete-video>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการข่าวประชาสัมพันธ์</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalNews = true"
              >เพิ่มข่าว</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <news-table
      ref="newsTable"
      class="mt-4"
      :fields="newsFields"
      :params="newsTableParams"
      @edit="onEditNews"
      @delete="onDeleteNews"
    />

    <create-or-update-news
      :edit-data.sync="newsEditData"
      v-model="showModalNews"
      @create:success="onCreateNewsSuccess"
    ></create-or-update-news>

    <delete-news
      v-model="showModalDeleteNews"
      @delete:success="onDeleteNewsSuccess"
      :delete-data.sync="newsDeleteData"
    ></delete-news>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between" style="min-height: 40px;">
          <b-col>
            <h5 class="mb-0">จัดการคู่มือแนะนำรายงานการขอเบิก</h5>
          </b-col>
          <b-col class="text-right" v-if="guidelineData.length < 1">
            <b-button variant="danger" @click="showModalGuideline = true"
              >เพิ่มคู่มือ</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <guideline-table
      ref="guidelineTable"
      class="my-4"
      :fields="guidelineFields"
      :params="guidelineTableParams"
      @edit="onEditGuideline"
      @delete="onDeleteGuideline"
    />

    <create-or-update-guideline-withdraw
      :edit-data.sync="guidelineEditData"
      v-model="showModalGuideline"
      @create:success="onCreateGuidelineSuccess"
    ></create-or-update-guideline-withdraw>

    <delete-guideline
      v-model="showModalDeleteGuideline"
      @delete:success="onDeleteGuidelineSuccess"
      :delete-data.sync="guidelineDeleteData"
    ></delete-guideline>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateBanner from "../../components/modal/CreateOrUpdateBanner";
import CreateOrUpdateNews from "../../components/modal/CreateOrUpdateNews";
import CreateOrUpdateVideo from "../../components/modal/CreateOrUpdateVideo";
import CreateOrUpdateGuidelineWithdraw from "../../components/modal/CreateOrUpdateGuidelineWithdraw.vue";
import BannerTable from "../../components/table/Banner";
import NewsTable from "../../components/table/News";
import VideoTable from "../../components/table/Video";
import GuidelineTable from "../../components/table/GuidelineWithdraw";
import DeleteBanner from "../../components/modal/DeleteBanner";
import DeleteNews from "../../components/modal/DeleteNews";
import DeleteVideo from "../../components/modal/DeleteVideo";
import DeleteGuideline from "../../components/modal/DeleteGuideline";
import { GuidelineWithdraw, Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateBanner,
    CreateOrUpdateNews,
    CreateOrUpdateVideo,
    CreateOrUpdateGuidelineWithdraw,
    BannerTable,
    NewsTable,
    VideoTable,
    GuidelineTable,
    DeleteBanner,
    DeleteNews,
    DeleteVideo,
    DeleteGuideline,
  },

  data() {
    return {
      bannerKeyword: "",
      keyword: "",
      guidelineData: {},
      bannerFormfilter: {},
      newsFormfilter: {},
      guidelineFormfilter: {},
      bannerEditData: {},
      newsEditData: {},
      videoEditData: null,
      guidelineEditData: {},
      bannerDeleteData: {},
      videoDeleteData: {},
      newsDeleteData: {},
      guidelineDeleteData: {},
      showModalBanner: false,
      showModalNews: false,
      showModalVideo: false,
      showModalGuideline: false,
      showModalDeleteBanner: false,
      showModalDeleteNews: false,
      showModalDeleteVideo: false,
      showModalDeleteGuideline: false,
      bannerFields: [
        { key: "banner_title", label: "ชื่อแบนเนอร์", sortable: true },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
      newsFields: [
        { key: "news_title", label: "หัวข้อข่าว", sortable: true },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
      videoFields: [
        { key: "video_name", label: "ชื่อวีดีโอ", sortable: true },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
      guidelineFields: [
        { key: "guideline_withdraw_title",
          label: "ชื่อรายงานการขอเบิก",
          sortable: true
        },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    bannerTableParams() {
      const { bannerFormfilter } = this;

      return {
        ...bannerFormfilter,
      };
    },

    newsTableParams() {
      const { newsFormfilter } = this;

      return {
        ...newsFormfilter,
      };
    },

    guidelineTableParams() {
      const { guidelineFormfilter } = this;

      return {
        ...guidelineFormfilter,
      };
    },
  },

  methods: {
    async fetchGuidelineWithdraw() {
      let data = [],
        additionalParams = {}

      if (this.guidelineTableParams && this.guidelineTableParams.keyword) {
        this.$set(additionalParams, "keyword", this.guidelineTableParams.keyword);
      }

      if (this.sortBy) {
        this.$set(additionalParams, "order", [
          this.sortBy,
          this.sortDesc ? "DESC" : "ASC",
        ]);
      }

      try {
        const promise = await GuidelineWithdraw.api().findAll(
          {
            ...additionalParams
          },
          { save: false }
        );

        let { data: responseData = [] } =
          promise.response.data;

        data = responseData;
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลประกาศได้ กรุณาลองใหม่อีกครั้ง");
      }

      this.guidelineData = data;
    },

    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    onEditBanner(item) {
      this.bannerEditData = { ...item };
      this.showModalBanner = true;
    },

    onEditNews(item) {
      this.newsEditData = { ...item };
      this.showModalNews = true;
    },

    onEditVideo(item) {
      this.videoEditData = { ...item };
      this.showModalVideo = true;
    },

    onEditGuideline(item) {
      this.guidelineEditData = { ...item };
      this.showModalGuideline = true;
    },

    onCreateBannerSuccess() {
      this.showModalBanner = false;
      this.$refs.bannerTable.refresh();
    },

    onCreateNewsSuccess() {
      this.showModalNews = false;
      this.$refs.newsTable.refresh();
    },

    onCreateVideoSuccess() {
      this.showModalVideo = false;
      this.$refs.videoTable.refresh();
    },

    onCreateGuidelineSuccess() {
      this.showModalGuideline = false;
      this.$refs.guidelineTable.refresh();
      this.fetchGuidelineWithdraw();
    },

    onDeleteBanner(item) {
      this.bannerDeleteData = { ...item };
      this.showModalDeleteBanner = true;
    },

    onDeleteNews(item) {
      this.newsDeleteData = { ...item };
      this.showModalDeleteNews = true;
    },

    onDeleteVideo(item) {
      this.videoDeleteData = { ...item };
      this.showModalDeleteVideo = true;
    },

    onDeleteGuideline(item) {
      this.guidelineDeleteData = { ...item };
      this.showModalDeleteGuideline = true;
    },

    onDeleteBannerSuccess() {
      this.$refs.bannerTable.refresh();
    },

    onDeleteNewsSuccess() {
      this.$refs.newsTable.refresh();
    },

    onDeleteVideoSuccess() {
      this.$refs.videoTable.refresh();
    },

    onDeleteGuidelineSuccess() {
      this.$refs.guidelineTable.refresh();
      this.fetchGuidelineWithdraw();
    },

    onHideVideo() {
      this.videoEditData = null;
    },
  },

  mounted() {
    this.fetchGuidelineWithdraw();
  },
};
</script>
