var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"d-flex flex-row flex-wrap"},[_c('div',{staticClass:"col-12 px-0"},[_c('filter-master',{attrs:{"m-headquarter-id":_vm.editData && _vm.editData.mHeadquarterId
            ? _vm.editData.mHeadquarterId
            : null,"m-division-id":_vm.editData && _vm.editData.mDivisionId
            ? _vm.editData.mDivisionId
            : null,"m-station-id":_vm.editData && _vm.editData.mStationId
            ? _vm.editData.mStationId
            : null,"disabled-input":_vm.disabledInput,"col-headquarter":"6","col-division":"6","col-station":"12","default-label":"-","hidden-input":{
          school: true,
          schoolClass: true,
          room: true,
        },"invalid":_vm.invalid,"validation":_vm.validation},model:{value:(_vm.filterMaster),callback:function ($$v) {_vm.filterMaster=$$v},expression:"filterMaster"}})],1),_c('div',{staticClass:"col-6 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"fiscalYear"}},[_vm._v("ปีการศึกษา")]),_c('v-select',{attrs:{"id":"fiscalYear","options":_vm.fiscalYears,"loading":_vm.isFetchingSemester},model:{value:(_vm.fiscal_year),callback:function ($$v) {_vm.fiscal_year=$$v},expression:"fiscal_year"}})],1),_c('div',{staticClass:"col-6 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"term"}},[_vm._v("เทอม")]),_c('v-select',{attrs:{"id":"term","options":_vm.terms,"loading":_vm.isFetchingSemester},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1),_c('div',{staticClass:"col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"quotaNumber"}},[_vm._v("จำนวนโควต้า")]),_c('b-form-input',{attrs:{"id":"quotaNumber","state":_vm.invalid ? _vm.validation.form.quota.required : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.form.quota),callback:function ($$v) {_vm.$set(_vm.form, "quota", $$v)},expression:"form.quota"}}),_c('b-form-invalid-feedback',{attrs:{"id":"quotaNumber-feedback"}},[_vm._v(" กรุณากรอกจำนวนโควต้า ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }