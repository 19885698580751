<template>
  <b-modal
    id="create-or-update-guideline-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">
        {{`${(editData && editData.id) ? "แก้ไข" : "เพิ่ม"}คู่มือแนะนำรายงานการขอเบิก`}}
      </h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด: {{ editData.updatedAt }}</span
      >
    </template>

    <guideline-form
      v-model="form"
      :edit-data="editData"
      :disabled="isSubmiting"
      :invalid.sync="invalid"
      :validation="$v"
    ></guideline-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click.prevent="save">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import GuidelineForm from "../form/GuidelineWithdraw.vue";
import { Auth, GuidelineWithdraw, TeachingDare } from "../../models";
import axios from "axios";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {
    GuidelineForm,
  },

  props: {
    show: Boolean,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      form: {},
    };
  },

  validations: {
    form: {
      guideline_withdraw_title: { required },
      // file: { required },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async save() {
      this.$v.$touch();

      this.invalid = false;

      if (this.$v.$invalid) {
        this.invalid = true;

        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      let formData = {
        ...this.form,
      };

      this.isSubmiting = true;

      let promise;

      try {
        let { file, ...rest } = formData;

        rest.key_img = await this.uploadPdf(file);
        
        if (this.editData && !this.editData.id) {
          promise = await GuidelineWithdraw.api().create({ ...rest });
        } else {
          promise = await GuidelineWithdraw.api().update(
            this.editData.id,
            { ...rest }
          );
        }

        const { data } = promise.response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }คู่มือแนะนำรายงานการขอเบิกเรียบร้อยแล้ว`
          );

          this.$bvModal.hide("create-or-update-announcement-modal");

          this.$emit("create:success");
        } else {
          this.$toast.error(
            data.response_description
            ? data.response_description
            : `${
                this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
              }คู่มือแนะนำรายงานการขอเบิกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    async uploadPdf(pdf) {
      try {
        if (!pdf) return null

        let uploadedUrls = "";
        
        const newImage = pdf;

        let uploadPromise = await TeachingDare.api().getUploadUrl({
          key: `${newImage.name.split(".")[0]}`,
          contentType: newImage.type,
          imageType: `.${newImage.type.split("/").pop()}`,
        });

        if (uploadPromise && uploadPromise.response) {
          const { url, key } = uploadPromise.response.data; // key, url, status

          if (url) {
            await axios.put(url, newImage);
          }
          uploadedUrls = key;
        }

        return uploadedUrls;
      } catch (error) {
        console.error(error);
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.invalid = false;
      this.form = {};

      this.$emit("update:editData", {});
    },
  },
};
</script>
