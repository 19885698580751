<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
      >	
				<template #head(actions)="row">
          <div class="text-center">
            <span>{{ row.label }}</span>
          </div>
        </template>
				
				<template #cell(actions)="{ item, index }">
          <div class="text-center">
            <toggle-button
              :key="`toggle-${item.id}-${index}`"
              color="#3d6b99"
              :value="item.is_open"
              :sync=true
              @change="toggleVisible(item, $event.value)"
            />
          </div>
        </template>
				
				<template #cell(balance)="{ item }">
          <div class="text-center">
            <span>{{ item.balance }}</span>
          </div>
        </template>
				
				<template #cell(quota)="{ item }">
          <div class="text-center">
            <span>{{ item.quota }}</span>
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import BaseTable from "./Base";
import { Auth, ConfigQuota } from "../../models";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    // actionButtonText: {
    //   type: String,
    //   default: "เพิ่ม/แก้ไข",
    // },
    // actionLabel: {
    //   type: String,
    //   default: "เพิ่ม/แก้ไขข้อมูล",
    // },
  },

  components: {
    BaseTable,
		ToggleButton,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      format: "",
      sortBy: "",
      sortDesc: false,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        params = {},
        additionalParams = {},
        numberOfPages = 1;

      this.isFetching = true;

      try {
        if (!ctx.downloadAll) {
          this.$set(params, "limit", ctx.perPage);
          this.$set(params, "offset", (ctx.currentPage - 1) * ctx.perPage);
        }

        if (this.params && this.params.mHeadquarterId) {
          this.$set(
            additionalParams,
            "mHeadquarterId",
            this.params.mHeadquarterId
          );
        }

        if (this.params && this.params.mDivisionId) {
          this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
        }

        if (this.params && this.params.mStationId) {
          this.$set(additionalParams, "mStationId", this.params.mStationId);
        }

        if (this.params && this.params.fiscal_year) {
          this.$set(
            additionalParams,
            "fiscal_year",
            this.params.fiscal_year
          );
        }

        if (this.params && this.params.term) {
          this.$set(additionalParams, "term", this.params.term);
        }

        if (this.sortBy) {
          let sortKey = this.sortBy;
          let sortDesc = this.sortDesc ? "DESC" : "ASC";
          if (this.sortBy === "balance") {
            sortKey = "used";
            sortDesc = this.sortDesc ? "ASC" : "DESC";
          } else if (this.sortBy === "created_at") {
            sortKey = "createdAt";
          }
          this.$set(additionalParams, "order", sortKey);
          this.$set(
            additionalParams,
            "order_by",
            sortDesc
          );
        }

        const promise = await ConfigQuota.api().findAll(
          { ...params, ...additionalParams },
          {
            save: false,
          }
        );

        let {
          data: responseData = [],
          number_of_pages = 1,
        } = promise.response.data;

        data = responseData.map((record) => {
          let yearTerm = record.m_semester.fiscal_year || "";

          if (record.m_semester.term) {
            yearTerm += `/${record.m_semester.term}`;
          }

          return {
            ...record,
            yearTerm,
            balance: +record.quota - +record.used,
            station: record.m_station ? record.m_station.m_station_name : null,
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลจำนวนโควต้าแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      if (!ctx.downloadAll) {
        this.numberOfPages = numberOfPages;
      }

      return data;
    },

    async toggleVisible(item, value) {
      let promise;

      try {
        promise = await ConfigQuota.api().update(item.id, {
          ...item,
          is_open: value,
        });
        const { data } = promise.response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(`อัพเดตการแสดงผลโควต้าแผนการสอนเรียบร้อยแล้ว`);
        } else {
          this.$toast.error(
            `อัพเดตการแสดงผลโควต้าแผนการสอนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถอัพเดตการแสดงผลโควต้าแผนการสอนได้ กรุณาลองใหม่อีกครั้ง");
      }

      this.refresh();
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
